import React from 'react'
import './CaseStudyCardHeader.scss'
import { FaCloud, FaMapMarker } from 'react-icons/fa'

class CaseStudyCardHeader extends React.Component {
  translateIcon(icon, color) {
    if (icon === 'cloud') {
      return <FaCloud color={color} />
    } else if (icon === 'pin') {
      return <FaMapMarker color={color} />
    }
  }

  render() {
    const { post } = this.props
    const { tags } = post
    if (!post) {
      return
    }
    return (
      <div className="case-study-card-header">
        {post.caseStudyLogo ? (
          <div
            className="left"
            style={{
              backgroundImage: post.caseStudyLogoBackground
                ? 'url(' + post.caseStudyLogoBackground.fluid.src + ')'
                : '',
            }}
          >
            {post.caseStudyLogoBackground ? (
              <div className="overlay" />
            ) : (
              <div className="overlay light" />
            )}
            <img src={post.caseStudyLogo.fluid.src} />
          </div>
        ) : null}
        <div className="right">
          <div className="inner">
            <div
              style={{ color: post.accentColor || '#2b8aec' }}
              className="title"
            >
              {post.title}
            </div>
            <div className="sub-title">{post.shortDescription}</div>
            <div className="tags-wrap">
              {tags.map(tag => (
                <div key={tag.slug} className="tag">
                  {tag.icon
                    ? this.translateIcon(
                        tag.icon,
                        post.accentColor || '#2b8aec'
                      )
                    : null}
                  {tag.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CaseStudyCardHeader
