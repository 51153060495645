import React from 'react'
import './Card.scss'
import BlogPostCardHeader from '../BlogPostCardHeader/BlogPostCardHeader'
import CaseStudyCardHeader from '../CaseStudyCardHeader/CaseStudyCardHeader'
import PodcastCardHeader from '../PodcastCardHeader/PodcastCardHeader'

const Card = props => {
  const type = props.post.type
  return (
    <div className={'big-card ' + (type === 'Podcast' ? 'nocard' : '')}>
      {type === 'Case Study' ? (
        <CaseStudyCardHeader post={props.post} />
      ) : type === 'Podcast' ? (
        <PodcastCardHeader post={props.post} />
      ) : (
        <BlogPostCardHeader post={props.post} />
      )}
      <section>{props.children}</section>
    </div>
  )
}

export default Card
