import React from 'react'
import './PostHero.scss'

export class PostHero extends React.Component {
  render() {
    const { post } = this.props
    return (
      <div
        className="post-hero"
        style={{ backgroundImage: post.type === 'Podcast' ? 'url(/cloud_geometric.jpg)' :'url(' + post.heroImage.fluid.src + ')' }}
      >
        <div className="overlay" />
      </div>
    )
  }
}

export default PostHero
