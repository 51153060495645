import React from 'react'
import './PodcastCardHeader.scss'
import { FaCloud, FaMapMarker } from 'react-icons/fa'

class PodcastCardHeader extends React.Component {
  translateIcon(icon, color) {
    if (icon === 'cloud') {
      return <FaCloud color={color} />
    } else if (icon === 'pin') {
      return <FaMapMarker color={color} />
    }
  }
  render() {
    const { post } = this.props
    const { tags, podcast } = post
    const { url } = podcast
    let w
    if (typeof window === 'undefined') {
      w = {
        innerWidth: 450,
      }
    } else {
      w = window
    }
    if (!post) {
      return
    }
    return (
      <div className="podcast-card-header">
        <div className="iframe-wrap">
          <iframe
            src={url}
            height="200px"
            width={
              (w.innerWidth >= 1200
                ? 800
                : w.innerWidth >= 815
                  ? 770
                  : w.innerWidth - 40) + 'px'
            }
            frameBorder="0"
            scrolling="no"
          />
        </div>
        <div className="tags-wrap">
          {tags.map(tag => (
            <div key={tag.slug} className="tag">
              {tag.icon ? this.translateIcon(tag.icon, '#000') : null}
              {tag.title}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default PodcastCardHeader
