import React from 'react'
import './BlogPostCardHeader.scss'
import { FaCloud, FaMapMarker } from 'react-icons/fa'

class BlogPostCardHeader extends React.Component {
  translateIcon(icon, color) {
    if (icon === 'cloud') {
      return <FaCloud color={color} />
    } else if (icon === 'pin') {
      return <FaMapMarker color={color} />
    }
  }
  render() {
    const { post } = this.props
    const { title, tags, author } = post
    return (
      <div className="blog-post-card-header">
        <div className="title">{title}</div>
        <div className="bottom-row">
          <div className="tags-wrap">
            {tags &&
              tags.map(tag => (
                <div key={tag.slug} className="tag">
                  {tag.icon
                    ? this.translateIcon(tag.icon, post.accentColor)
                    : null}
                  {tag.title}
                </div>
              ))}
          </div>
          <div className="buffer" />
          <div className="author">
            <div className="name">By {author.name}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogPostCardHeader
