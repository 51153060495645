import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import './base.scss'
import config from '../utils/siteConfig'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import Card from '../components/Card/Card'
import PostHero from '../components/PostHero/PostHero'
import SEO from '../components/SEO'
import BigContentCard from '../components/BigContentCard/BigContentCard'
import { handleCustomContent, handleEmbeds } from '../utils/contentMapping'
import CallToActionBanner from '../components/CallToActionBanner/CallToActionBanner'

const PostTemplate = ({ data, pageContext }) => {
  const { title, slug, body, suggestedContent } = data.contentfulPost
  const postNode = data.contentfulPost
  const renderContent = () => {
    let generated = handleCustomContent(
      body.childContentfulRichText.html,
      postNode
    )
    if (generated && generated.length && Array.isArray(generated)) {
      return generated.map(c => {
        if (typeof c === 'string') {
          return <div dangerouslySetInnerHTML={{ __html: c }} />
        } else {
          return handleEmbeds(c, postNode)
        }
      })
    } else if (typeof generated === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: generated }} />
    }
  }

  return (
    <Layout headerType={'clear'}>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} postSEO />
      <PostHero post={data.contentfulPost} />
      <Container color={'light-grey'} type={'card'}>
        <Card post={postNode}>{renderContent()}</Card>
      </Container>
      <Container color={'light-grey'} type={'big-padding padding-top-none'}>
        {suggestedContent ? (
          <BigContentCard color={'white'} content={suggestedContent} />
        ) : null}
      </Container>
      <CallToActionBanner type={'signup'} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      shortDescription
      slug
      type
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      accentColor
      heroImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      caseStudyLogo {
        fluid(maxWidth: 500) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      author {
        name
        title
        image {
          fluid(maxWidth: 900) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      body {
        childContentfulRichText {
          html
        }
      }
      podcast {
        hostingType
        url
      }
      tags {
        title
        slug
        icon
      }
      suggestedContent {
        type
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        shortDescription
        title
        slug
      }
    }
  }
`

export default PostTemplate
